@import './styles-vars.less';

.upperCase() {
  text-transform: uppercase;
}

.subtitle-bit() {
  background-color: #e5eeef;
  border-radius: @base-border-radius;
  width: 100%;
  color: #63727d;
  height: 36px;
  font-weight: @typography-title-font-weight;
  margin-bottom: @base-margin;
  .flex-start();

  > span {
    .upperCase();
    margin: @base-margin;
    font-size: @typography-small;
  }
}

.subtitle-bit-optional() {
  .subtitle-bit();
  background-color: #eaecf2;
}

.modal-header() {
  background-color: #f4f6f9;
  height: 100px;
  border-radius: @modal-border-radius;
}

.modal-content() {
  border-radius: @modal-border-radius;
  min-width: 915px;
}

.flex-start() {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-label() {
  .upperCase();
  font-size: @typography-small;
}
