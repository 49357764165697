@import '~antd/dist/antd.less';
@import './styles-mixins.less';

// Base Scaffolding Variables
@text-color: #63727d;
@heading-color: #2d2d2d;
@base-margin: 15px;
@base-padding: 15px;
@base-border-radius: 12px;

// Buttons
@btn-border-radius-base: 4px;

// Colors
@error-color: #e61c40;
@input-border-color: #dedede;
@input-color: #838485;
@fill-color-grey: #63727d;
@input-weight: 500;
@modal-header-color: #f4f6f9;
@sub-header-gray: #919da6;

// Drawer
@drawer-body-padding: @padding-md;

// Layout
@layout-header-background: #ffffff;

// Form
@form-vertical-label-padding: 0;

// Modal
@modal-header-bg: #e7ebf2;

// Paddings
@padding-lg: 50px;

// PageHeader
@page-header-back-color: #e7ebf2;
@page-header-ghost-bg: #e7ebf2;

// Shadow
@box-shadow-base: 0px 15px 30px rgba(0, 0, 0, 0.08);

// Typography
// ---
@typography-title-font-weight: 500;
@typography-title-margin-bottom: 0;
@typography-small: 10px;

// Inputs
@base-input-width: 140px;

// Modal
@modal-border-radius: 8px;
@modal-body-padding: 24px;
@modal-header-padding: 24px;

.layout {
  min-height: calc(100vh - 150px);
}

.user-actions {
  .ant-popover-arrow {
    visibility: hidden;
  }
}

header {
  min-width: 700px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);

  .logo {
    .flex-start();
      padding: 0 18px;
      img {
        height: 30px;
      }
  }

  .logo-text {
    position: absolute;
    width: 131px;
    height: 15px;
    left: calc(50% - 131px/2 - 10px);
    top: calc(50% - 15px/2 + 16.46px);
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 135%;
    color: #777777;
  }

  .user {
    border-left: 1px solid #eeeff1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .ant-menu-submenu-title {
    padding-right: 16px !important;
  }

  .ant-menu-vertical {
    border: none;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-row {
    height: 100%;
  }
}

@keyframes loading-keyframes {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.loading-logo {
  animation: loading-keyframes 2s linear infinite;
  height: 68px;
}

.loading-container {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.ant-page-header {
  width: 100%;
  min-width: 700px;
  min-height: 88vh;
}

.main-content {
  border: 1px solid white;
  border-radius: @base-border-radius;
  padding: 0 !important;
}

.padded-content {
  padding: 24px !important;
}

.input {
  color: @input-color;
  font-weight: @input-weight;
}

.select {
  color: @input-color;
  font-weight: @input-weight;
}

.rules-grid-row {
  margin-top: @base-margin;
  display: flex;
  align-items: baseline;

  > * {
    flex: 0 0 200px;
    overflow: hidden;
  }

  > .operator {
    margin-left: 0;
    margin-right: 0;
    flex: 1 1 auto;
  }

  .rules-grid-spacer {
    flex: 0 0 30px;
    height: 1px;
    background-color: #dedede;
    transform: translateY(12px);
  }

  .rules-grid-spacer.readonly {
    transform: translateY(12px);
  }

  .rules-grid-cell {
    .ant-form-item-label {
      overflow: visible;
      .upperCase();
    }

    label,
    .label {
      font-size: @typography-small;
      transform: translate(10px, 0px);
      overflow: visible;
      z-index: 100;
    }

    span.label {
      display: block;
      transform: translate(0);
      .upperCase();
    }

    span.value {
      display: block;
    }

    .ant-form-item-control {
      min-height: 64px;
    }

    input,
    .input {
      box-sizing: border-box;
      border-radius: 6px;
      height: 64px;
      overflow: hidden;
      white-space: nowrap;
    }

    div.input {
      .input();
      border: 1px solid #dedede;
      padding: 4px 11px;
      text-overflow: unset;
      overflow-x: scroll;
    }

    .ant-select-selector {
      border-radius: 6px;
      height: 64px;
    }

    .ant-form-item-label {
      width: 0;
    }

    .ant-select-selection-item {
      transform: translate(0px, 16px);
    }
  }

  .container-del {
    width: 0px;
    overflow: visible;
    flex: 0 0 0px;
    .btn-del {
      width: 32px;
      height: 32px;
      background-color: #c64b4b;
      border-radius: 100px;
      transform: translate(-16px, 25px);
      box-shadow: 0 0 0 2px white;
      padding: 4px 15px;
    }
  }
}

.rules-grid-and {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: @base-margin;

  span {
    border-radius: 44px;
    border: 1px dotted #dedede;
    width: 72px;
    height: 24px;
    text-align: center;
  }
}

.ruleset-modal {
  display: flex;
  flex-direction: column;

  .ant-modal-header {
    .modal-header();
  }

  .ant-modal-content {
    .modal-content();
  }
  & .rules-grid-and {
    margin-top: 0;
  }

  .modal-footer {
    .flex-center();
  }

  .rules-form {
    > * {
      width: 100%;
    }

    .document-select,
    .routing-rule-input,
    .ant-select {
      width: 100%;
    }

    label {
      .form-label();
    }

    .rules-matching-description {
      background-color: #e9ecf1;
      border-radius: @base-border-radius;
      width: 100%;
      color: #63727d;
      height: 36px;
      font-weight: @typography-title-font-weight;
      .flex-start();

      > span {
        .upperCase();
        margin: @base-margin;
        font-size: @typography-small;
      }
    }
  }
  .rules-grid-row();

  .container-add {
    display: flex;
    justify-content: flex-end;

    .btn-add {
      border-radius: 100px;
      width: 32px;
      height: 32px;
      background-color: @fill-color-grey;
      display: inline-block;
      flex: 0 0 32px;
      padding: 4px 15px;
    }
  }

  .ant-btn:empty {
    visibility: visible;
  }
}

div.ruleset-drawer {
  margin-top: 0 !important;

  img {
    width: 80px;
  }

  .title-ruleset-drawer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .ruleset-drawer-document,
  .ruleset-drawer-partner-name {
    padding: 0;
  }

  .rules-matching-description {
    background-color: #e9ecf1;
    border-radius: @base-border-radius;
    width: 100%;
    color: #63727d;
    height: 36px;
    font-weight: @typography-title-font-weight;
    margin-bottom: @base-margin;
    padding-left: 15px;
    .flex-start();

    > span {
      .upperCase();
      font-size: @typography-small;
    }
  }

  .header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: @base-margin;
    > :first-child {
      flex: 0 0 80px;
      margin-right: @base-margin;
    }

    > :last-child {
      flex: 0 0 170px;
      align-self: flex-end;
    }

    > :not(:first-child):not(:last-child) {
      flex: 1 1 auto;
      color: @sub-header-gray;
      align-self: flex-end;
      p {
        font-size: @typography-small;
        margin-bottom: 0px;
      }
      margin-right: @base-margin;
    }

    .rule-details {
      display: flex;
      flex-direction: column;
    }

    .rule-modificationTime {
      color: @sub-header-gray;
      font-size: @typography-small;
      text-align: right;
    }
  }

  .rules-grid-row();

  .buttons {
    display: flex;
    min-width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: @base-margin;
  }
}

.delete-modal {
  h2 {
    text-align: center;
  }

  .content {
    text-align: center;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px;
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.btn-secondary {
  background-color: @fill-color-grey;
  color: #fff;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: @fill-color-grey;
  opacity: 0.9;
  color: #fff;
  border: 1px solid transparent;
}

.ant-table-cell {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
